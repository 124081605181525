import { useState } from 'react';
import './App.css';
import Main from '../Main/Main';
import Footer from '../Footer/Footer';
import useWindowSize from '../../assets/hooks/useWindowSize';
import { Router } from 'react-router-dom';

function App() {
  const [step, setStep] = useState(
    localStorage.getItem('card_data') !== null ? 2 : 0
  );
  const { height } = useWindowSize();

  return (
    <div
      className={`app ${step === 0 ? 'app__color_contrast' : ''}`}
      style={{ minHeight: height }}
    >
      <Main {...{ step, setStep }} />
    </div>
  );
}

export default App;
