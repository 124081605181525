import { TextLogoIcon } from '../../../assets/icons/icons';
import card from '../../../assets/images/card-bg.png';
import apple from '../../../assets/images/apple-wallet-icon.webp';
import google from '../../../assets/images/google-wallet-btn.png';
import './SuccessMsg.css';

function SuccessMsg({ cardData }) {
  return (
    <div className="success">
      <p className="success__title">ПОЗДРАВЛЯЕМ!</p>
      <p className="success__subtitle">
      Ваша карта участника выпущена
      </p>

      <div className="success__card">
        <img className="success__card-img" src={card} alt="" />
      </div>

      <ul className="success__wallets">
        <li className="success__item">
          <a
            className="success__wallet-btn"
            href={cardData?.cards?.apple_wallet?.file?.url}
            target="_blank"
            rel="noreferrer"
          >
            <img className="success__wallet-icon" src={apple} alt="" />
            <p className="success__wallet-text">Добавить в Apple Wallet</p>
          </a>
        </li>

        <li className="success__item">
          <a className="success__wallet-btn" type="button" href={`intent://import/${cardData?.cards?.apple_wallet?.file?.url}#Intent;scheme=walletpasses;package=io.walletpasses.android;S.browser_fallback_url=https://play.google.com/store/apps/details?id=io.walletpasses.android&hl=ru&referrer=${encodeURI(cardData?.cards?.apple_wallet?.file?.url)};end`}>
            <img className="success__wallet-icon-google" src={google} alt="" />
          </a>
        </li>
      </ul>
    </div>
  );
}

export default SuccessMsg;
