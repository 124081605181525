import './Footer.css';

function Footer({ step }) {
  return (
    <footer className="footer">
      <p
        className={`footer__text ${step === 0 ? 'footer__text_color_contrast' : ''
          }`}
      >
        ВМЕСТЕ х BIG BRO PRO x GOELRO x SM GROUP
      </p>
    </footer>
  );
}

export default Footer;
