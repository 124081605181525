import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { motion, AnimatePresence } from 'framer-motion';
import './Main.css';
import { LogoIcon } from '../../assets/icons/icons';
import SuccessMsg from './SuccessMsg/SuccessMsg';
import MiniPreloader from '../MiniPreloader/MiniPreloader';
import mainApi from '../../assets/api/MainApi';
import { parseApiError } from '../../assets/utils/utils';
import useAutoDismissError from '../../assets/hooks/useAutoDismissError';
import dust from '../../assets/images/dust.png'
import { useSearchParams } from 'react-router-dom';
import { LS_NAME } from '../../assets/utils/constants';
import Footer from '../Footer/Footer';

function Main({ step, setStep }) {
  const [error, showError] = useAutoDismissError();
  const [isPreloader, setIsPreloader] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const email = (searchParams.get("email"));
  const name = (searchParams.get("name"));
  const surname = (searchParams.get("surname"));
  const regcode = (searchParams.get("regcode"));
  const paramsData = {
    email: email && email !== '{email}' ? email : null,
    full_name: name && name !== '{name}' ? name : null,
    name: name && name !== '{name}' ? name : null,
    last_name: surname && surname !== '{surname}' ? surname : null,
    regcode:  regcode && regcode !== '{regcode}' ? regcode : null,
  }

  const [cardData, setCardData] = useState(
    localStorage.getItem(LS_NAME) !== null
      ? (JSON.parse(localStorage.getItem(LS_NAME)))
      : undefined
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  function handleSubmitForm() {
    setIsPreloader(true);
    mainApi
      .signup(paramsData)
      .then((res) => {
        setStep(2);
        localStorage.setItem(LS_NAME, JSON.stringify(res.data));
        setCardData(res.data);
      })
      .catch((err) => {
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloader(false);
      });
  }

  useEffect(() => {
    if ((email && email !== `{email}`) || (surname && surname !== `{surname}`) || (name && name !== `{name}`) || (regcode && regcode !== `{regcode}`)) {
      handleSubmitForm()
    }
  }, []);

  // console.log(email)
  useEffect(() => {
    if ((email && email !== `{email}`) || (surname && surname !== `{surname}`) || (name && name !== `{name}`) || (regcode && regcode !== `{regcode}`)) {
      localStorage.setItem(LS_NAME, JSON.stringify(paramsData))
    }
  }, [email, surname, name, regcode])

  return (
    <>
      {isPreloader ?
        <MiniPreloader />
        :
        <main className={`main ${step === 0 ? 'main_type_start' : ''}`}>
          <div
            className={`main__content ${step === 0 ? 'main__content_type_start' : ''
              }`}
          >
            <img src={dust} alt='' className='main__img-dust'></img>
            <AnimatePresence initial={false} mode="popLayout">
              {step === 0 && (
                <motion.div
                  className="main__container"
                  key={step}
                  initial={{ opacity: 1 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="main__start-box">
                    <LogoIcon
                      mainClassName="main__start-logo"
                      fillClassName="main__start-logo-fill"
                    />

                    <h1 className="main__start-text">
                      Получите карту участника
                    </h1>
                  </div>
                </motion.div>
              )}

              {step === 2 && (
                <motion.div
                  className="main__container"
                  key={step}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <SuccessMsg {...{ cardData }} />
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          <div className="main__footer">
            {step === 0 && (
              <p className="main__agreement">
                Нажимая кнопку «Получить», вы принимаете
                <br />
                <a className="main__agreement-link" href="/#" target="_blank">
                  условия договора публичной оферты
                </a>
              </p>
            )}

            <AnimatePresence initial={false}>
              {step !== 2 && (
                <motion.button
                  className={`main__btn`}
                  onClick={handleSubmitForm}
                  key="button"
                  initial={{ opacity: 1, backgroundColor: 'var(--inactive-btn-bg-purple)' }}
                  animate={{
                    opacity: 1,
                    backgroundColor: 'var(--active-btn-bg)',
                  }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <AnimatePresence initial={false} mode="popLayout">
                    {isPreloader ? (
                      <motion.div
                        className="main__preloader"
                        key="preloader"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        {/* <MiniPreloader /> */}
                      </motion.div>
                    ) : (
                      <motion.p
                        className="main__btn-text"
                        key={step}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        {'Получить'}
                      </motion.p>
                    )}
                  </AnimatePresence>
                </motion.button>
              )}
            </AnimatePresence>

            <AnimatePresence initial={false}>
              {Boolean(error) && (
                <motion.div
                  className="main__error"
                  initial={{
                    height: '0px',
                    marginTop: '0px',
                    opacity: 0,
                    visibility: 'hidden',
                  }}
                  animate={{
                    height: 'fit-content',
                    marginTop: '6px',
                    opacity: 1,
                    visibility: 'visible',
                  }}
                  exit={{
                    height: '0px',
                    marginTop: '0px',
                    opacity: 0,
                    visibility: 'hidden',
                  }}
                  transition={{ duration: 0.2 }}
                >
                  <p className="main__error-msg">{error}</p>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </main>
      }
      {!isPreloader ?
        <Footer />
        : null
      }
    </>
  );
}

export default Main;
