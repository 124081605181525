import { MAIN_URL } from '../utils/constants';

class MainApi {
  constructor({ baseUrl }) {
    this._BASE_URL = baseUrl;
  }

  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    } else {
      const statusCode = res.status;
      return res.json().then((res) => {
        return Promise.reject({
          statusCode: statusCode,
          message: res.msg,
          detail: res.detail,
        });
      });
    }
  }

  signup({ email, name, last_name, regcode }) {
    return fetch(`${MAIN_URL}/loyalty/signup`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        name: name,
        last_name: last_name,
        regcode: regcode
      }),
    }).then(this._checkResponse);
  }
}

const mainApi = new MainApi({
  baseUrl: MAIN_URL,
});

export default mainApi;
